import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useSpeechSynthesis } from 'react-speech-kit';

function App() {
  const [nomor, setNomor] = useState('')
  const [perkara, setPerkara] = useState({})
  const [isDifabel, setIsDifabel] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onDismiss = () => setMessage('')

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const { speak, voices } = useSpeechSynthesis();

  useEffect(() => {
    if (isDifabel)
      setNomor(transcript.trim());

    if (nomor !== '' && isDifabel && !listening) {
      letSpeak('oke, saya carikan');
      getPerkara();
    }
  }, [nomor, transcript, listening])

  const getPerkara = () => {
    setPerkara({})
    if (nomor === '') {
      letSpeak('Silahkan masukkan nomor perkara Anda');
      setMessage('Silahkan masukkan nomor perkara Anda');
      return;
    }

    letSpeak('oke, saya carikan');
    setLoading(true)
    const endpoint = "http://sipp.pa-muarabulian.go.id/list_perkara/api_search";
    var formData = new FormData()
    formData.append('keyword', nomor)
    axios.post(endpoint, formData, {
      headers: {
        'x-access-token': '8a7hsfasjkn98AYSUFNA9jkaskjgas9kajsd9H9HAKJAKSJkjasjk'
      }
    }).then(res => {
      // console.log(res.data);
      setLoading(false)
      setIsDifabel(false)
      if (res.data.rows > 0) {
        setMessage('')
        setPerkara(res.data.perkara)

        let status = 'belum terbit, mohon menunggu proses penerbitan akta cerai'
        if (res.data.perkara.IDProses === "296")
          status = 'sudah terbit, silahkan ambil ke kantor pengadilan agama muara bulian dengan membawa ktp'

        letSpeak('data ditemukan');
        letSpeak('status nomor perkara anda ' + status);
      } else {
        setPerkara({})
        setMessage('Data tidak ditemukan, silahkan coba lagi')
        letSpeak('data tidak ditemukan, silahkan coba lagi');
      }
    }).catch(err => {
      setPerkara({})
      setLoading(false)
      setIsDifabel(false)
      setMessage('Data tidak ditemukan, silahkan coba lagi')
      letSpeak('data tidak ditemukan, silahkan coba lagi');
      console.log(err);
    });
  }

  const letSpeak = (value) => {
    let indexId = -1
    voices.find((item, i) => {
      if (item.lang === 'id-ID') {
        indexId = i
        return i;
      }
    })

    if (indexId === -1)
      voices.find((item, i) => {
        if (item.lang === 'en-US') {
          indexId = i
          return i;
        }
      })

    speak({
      text: value,
      voice: voices[indexId]
    })
  }

  const onDifabel = async () => {
    // setIsDifabel(true);
    setPerkara({})
    letSpeak('Assalamualaikum, silahkan masukkan nomor perkara anda');
    // setTimeout(() => {
    //   SpeechRecognition.startListening({ continuous: false, language: 'id' })
    // }, 4000);
  }

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    permissions.then((stream) => {
      // console.log(stream);
      onDifabel();
    }).catch((err) => {
      console.log(`${err.name} : ${err.message}`)
    });
  }

  return (
    <div className="main-content pb-5">
      <div className="header">
        <Container>
          <div className="header-body text-center pt-5 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="6">
                <h1 className="text-white display-4">e-Akta Cerai</h1>
                <h5 className="text-lead text-light">
                  Temukan informasi penerbitan akta cerai Anda
                </h5>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className='mt-5'>
        <Row lg="2" sm="1" className="justify-content-center px-3 mb-5">
          <Col>

            <Card className='shadow border-0 p-0 py-3 px-3 px-lg-4'>
              <CardBody className='d-flex flex-column align-items-center justify-content-center'>

                <Form className='w-100'>
                  <FormGroup>
                    <Label>
                      Nomor Perkara
                    </Label>
                    <Input placeholder='contoh : 404/Pdt.G/2020/PA.Mbl' value={nomor} onChange={(event) => setNomor(event.target.value)} onKeyDown={(event) => event.key === 'Enter' ? (getPerkara(), event.preventDefault()) : null} />
                  </FormGroup>
                </Form>

                {perkara.IDPerkara ?
                  <Alert className='d-flex flex-column mt-1 align-items-center justify-content-center' color={perkara.IDProses === "296" ? "success" : "primary"}>
                    <p className='font-weight-bold m-0'>{perkara.noPerkara}</p>
                    <h5 className='m-0'>{perkara.IDProses === "296" ? 'Sudah Terbit' : 'Belum Terbit'}</h5>
                    <small className='text-center text-warning mt-1'>*{perkara.IDProses === "296" ? 'Silahkan ambil ke kantor Pengadilan Agama Muara Bulian dengan membawa KTP' : 'Mohon menunggu proses penerbitan akta cerai'}</small>
                  </Alert>
                  : null}

                <Button
                  disabled={loading}
                  onClick={() => getPerkara()}
                  color="success" className='w-100 mt-1'>
                  Cari
                </Button>

                <Alert className='mt-3' color="danger" isOpen={message ? true : false} toggle={onDismiss}>
                  {message}
                </Alert>

                <Button
                  className='mt-4'
                  onClick={() => onDifabel()}
                  disabled={listening}
                  color={isDifabel ? "success" : "warning"}>
                  <i
                    className="fa fa-volume-up" aria-hidden="true"></i>
                </Button>

                {!browserSupportsSpeechRecognition ?
                  <span>Browser tidak support speech recognition!</span>
                  : null
                }

                {/* <div className='mt-5'>
                <p>Microphone: {listening ? 'on' : 'off'}</p>
                <button onClick={() => SpeechRecognition.startListening({ continuous: true, language: 'id' })}>Start</button>
                <button onClick={SpeechRecognition.stopListening}>Stop</button>
                <button onClick={resetTranscript}>Reset</button>
                <p>{transcript}</p>
              </div> */}

              </CardBody>
            </Card>

          </Col>
        </Row>

        <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
          <img src="/logo192.png" alt="image" style={{ width: 50 }} />
          <span className='mt-3 text-white'>Pengadilan Agama Muara Bulian</span>
          <span className='text-white'>www.pa-muarabulian.go.id | 0743-21073</span>
        </div>
      </Container>
    </div>
  );
}

export default App;
